<template>
  <nav class="asf-breadcrumbs" aria-label="Breadcrumbs">
    <AsfList tag="ol" class="asf-breadcrumbs__list">
      <AsfListItem v-if="homeLabel" class="asf-breadcrumbs__item">
        <slot name="content-home-link" v-bind="{ homeLabel }">
          <AsfChevron appearance="left" />
          <AsfLink link="/">
            {{ homeLabel }}
          </AsfLink>
        </slot>
      </AsfListItem>
      <AsfListItem
        v-for="(breadcrumb, i) in breadcrumbs"
        :key="breadcrumb.id"
        class="asf-breadcrumbs__item"
        :class="{
          'm-last': lastIndex === i && !isSingleItem,
          'is-shown': preLastIndex === i || isSingleItem
        }"
        :aria-current="lastIndex === i && 'page'"
      >
        <AsfChevron v-if="lastIndex !== i || isSingleItem" appearance="left" />
        <AsfLink :link="breadcrumb.link" tabindex="0" v-e2e="'navigation-breadcrumbs'">
          {{ breadcrumb.text || breadcrumb.link }}
        </AsfLink>
      </AsfListItem>
    </AsfList>
  </nav>
</template>

<script lang="ts">
import type { AsfBreadcrumbsProps } from '@ui/types'

export default defineComponent({
  name: 'AsfBreadcrumbs',
  props: {
    homeLabel: { type: String as PropType<AsfBreadcrumbsProps['homeLabel']>, default: '' },
    breadcrumbs: { type: Array as PropType<AsfBreadcrumbsProps['breadcrumbs']>, default: () => [] }
  },
  setup: (props: AsfBreadcrumbsProps) => ({
    lastIndex: computed(() => props.breadcrumbs.length - 1),
    preLastIndex: computed(() => props.breadcrumbs.length - 2),
    isSingleItem: computed(() => props.breadcrumbs.length === 1)
  })
})
</script>
<style lang="postcss">
@import '@components/molecules/Breadcrumbs/Breadcrumbs.css';
</style>
